import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { type ParamType } from '../types/url-params';

// we replace the parameter if the value is an array or boolean
const isArrayParamType = (value: any) => Array.isArray(value);
const isBooleanParamType = (value: any) => typeof value === 'boolean';

// hook for shared functions to manage url params
const useUrlParams = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = history.location.pathname;
  const searchParams = new URLSearchParams(history.location.search);

  const updateUrlParam = useCallback(
    (params: ParamType, replaceParam?: boolean) => {
      const { parameterKey, parameterValue } = params;

      // if the type is array or boolean, we always replace the value
      // we can add the other case if we get the requirement
      if (isBooleanParamType(parameterValue)) {
        searchParams.set(parameterKey, JSON.stringify(parameterValue));
      }

      if (isArrayParamType(parameterValue)) {
        searchParams.set(parameterKey, JSON.stringify(parameterValue));
      }

      // replace param currently only used for the currency dropdown
      if (typeof parameterValue === 'string' && replaceParam) {
        searchParams.set(parameterKey, parameterValue);
      } else if (typeof parameterValue === 'string') {
        if (searchParams.has(parameterKey)) {
          const paramValues = searchParams.getAll(parameterKey)[0].split(',');
          const paramExistsInUrl = paramValues.includes(parameterValue);

          // if the parameter exists in the url, we remove it
          if (paramExistsInUrl) {
            const filteredValues = paramValues.filter(
              (paramValues) => paramValues !== parameterValue,
            );

            if (filteredValues.length === 0) {
              searchParams.delete(parameterKey);
            } else {
              searchParams.set(parameterKey, filteredValues.join(','));
            }
          } else {
            // otherwise we add it
            searchParams.set(
              parameterKey,
              paramValues.concat(parameterValue).join(','),
            );
          }
        } else {
          searchParams.set(parameterKey, parameterValue);
        }
      }

      history.push({ pathname, search: searchParams.toString() });
    },
    [history, searchParams, pathname],
  );

  // this function gets the parameters from the url and deserializes it
  const deserializeUrl = useCallback(() => {
    const result = [] as ParamType[];

    searchParams.forEach((value, key) => {
      // if the serialised value contains an array, we parse it to keep the array structure with the key
      if (value.includes('[')) {
        result.push({
          parameterKey: key,
          parameterValue: JSON.parse(value),
        });
      } else {
        // if the serialised value is a comma seperated string, we split it into an array
        value.split(',').forEach((value) => {
          result.push({
            parameterKey: key,
            parameterValue: value.trim(),
          });
        });
      }
    });

    return result;
  }, [searchParams]);

  const clearAllUrlParams = useCallback(
    ({ ignoreClearKeys = [] }: { ignoreClearKeys: string[] }) => {
      const updatedQueryParams = new URLSearchParams();

      [...ignoreClearKeys].forEach((paramName) => {
        const paramValue = searchParams.get(paramName);
        if (paramValue) {
          updatedQueryParams.set(paramName, paramValue);
        }
      });

      history.push({ pathname, search: updatedQueryParams.toString() });
    },
    [searchParams],
  );

  const clearUrlParam = useCallback(
    (parameterKey: string) => {
      searchParams.delete(parameterKey);
      history.push({ pathname, search: searchParams.toString() });
    },
    [searchParams],
  );

  const getUrlParam = (key: string) => searchParams.get(key);

  const paramState = useMemo(() => deserializeUrl(), [location.search]);

  return {
    paramState,
    deserializeUrl,
    clearAllUrlParams,
    clearUrlParam,
    updateUrlParam,
    getUrlParam,
  };
};

export default useUrlParams;
