import { type BuyingFilters } from '../../types/filters';

export const checkHasActiveQuantificationFilters = ({
  filters,
}: {
  filters: BuyingFilters | undefined;
}) => {
  if (!filters) return false;
  return Object.values(filters).some((item) => Object.keys(item).length > 0);
};
