import { useAutoneTranslation } from '@autone/translations';
import ReplyIcon from '@mui/icons-material/Reply';
import { Button, useTheme } from '@mui/material';
import { type FC, useEffect, useState } from 'react';

import { type APP_NAME } from '../../../constants';
import {
  SHARE_URL_BUTTON_CLICKED,
  trackMixpanelAction,
} from '../../../utils/mixpanel';

const ShareURLButton: FC = () => {
  const theme = useTheme();
  const [isCopied, setIsCopied] = useState<Boolean>(false);
  const { t, common } = useAutoneTranslation<typeof APP_NAME>();

  const handleCopyUrl = (): void => {
    const url = window.location.href;
    trackMixpanelAction(SHARE_URL_BUTTON_CLICKED, { url });
    window.navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  // after 3 seconds, set copied to false to switch back to 'Share link' text
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isCopied) {
      timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  return (
    <Button
      onClick={handleCopyUrl}
      endIcon={<ReplyIcon sx={{ transform: 'scaleX(-1)' }} />}
      sx={{
        color: theme.palette.grey[800],
        height: 'fit-content',
        textTransform: 'none',
      }}
      variant="text"
    >
      {isCopied ? `${common('copied')}!` : t('header.share')}
    </Button>
  );
};

export default ShareURLButton;
