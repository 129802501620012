// Need to use the React-specific entry point to allow generating React hooks
import { customBaseQuery } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getErrors } from './errors';

const TAG_TYPES = [
  'Batches',
  'Batch',
  'Scope',
  'Products',
  'ProductFilters',
  'ProductMismatches',
  'TopProducts',
  'Assortment',
  'AssortmentFilters',
  'AssortmentMismatches',
  'Review',
  'IdealBuyKpis',
  'IdealBuyKpisWithFiltered',
  'IdealBuy',
  'IdealBuyAggregated',
  'IdealBuyFilters',
  'ImportOtb',
  'CatalogueDepartmentSkuCap',
];

// Define a service using the base buying URL and expected endpoints
export const buyingApi = createApi({
  reducerPath: 'buyingApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) =>
    // custom query adds auth headers and error handling
    {
      if (typeof args === 'string') {
        return customBaseQuery(
          {
            url: args,
            extraArgs: { getErrors },
          },
          api,
          extraOptions,
          'v2/buying',
        );
      } else {
        return customBaseQuery(
          {
            ...args,
            extraArgs: { getErrors },
          },
          api,
          extraOptions,
          'v2/buying',
        );
      }
    },

  endpoints: () => ({}),
});
