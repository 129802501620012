import {
  type CreateBatchRequest,
  type CreateBatchResponse,
} from '@autone/openapi-buying';
import { isEmpty } from 'lodash';

import { buyingApi } from '../apis/buying';

const createBuyApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    postCreateBatch: builder.mutation<CreateBatchResponse, CreateBatchRequest>({
      query: ({ batch }) => ({
        url: `batch`,
        extraArgs: { hideSnackbar: true },
        method: 'POST',
        body: {
          batch: {
            ...batch,
            comparables: isEmpty(batch.comparables) ? null : batch.comparables,
          },
        },
      }),
      invalidatesTags: ['Batches'],
    }),
  }),
});

export const { usePostCreateBatchMutation } = createBuyApis;
