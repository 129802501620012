import {
  type GetProductTagsResponse,
  type ImportProductTagsResponse,
} from '@autone/openapi-buying';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { buyingApi } from '../apis';

type UploadExportTagFileError = {
  data: {
    detail: string;
  };
};

const isErrorResponse = <T extends Record<string, unknown>>(
  response: unknown,
): response is T => {
  return (
    typeof (response as FetchBaseQueryError).status === 'number' &&
    typeof (response as FetchBaseQueryError).data === 'object'
  );
};

const productTags = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadExportTagFile: builder.query<GetProductTagsResponse, void>({
      query: () => ({
        url: 'product-tags/export',
        extraArgs: { hideSnackbar: true },
      }),
    }),
    uploadExportTagFile: builder.mutation<ImportProductTagsResponse, FormData>({
      query: (formData) => ({
        url: 'product-tags/import',
        method: 'POST',
        body: formData,
        extraArgs: { hideSnackbar: true },
      }),
      transformErrorResponse: (response) => {
        if (isErrorResponse<UploadExportTagFileError>(response)) {
          return response.data.detail;
        }

        return response;
      },
    }),
  }),
});

export const {
  useLazyDownloadExportTagFileQuery,
  useUploadExportTagFileMutation,
} = productTags;
