import { userApi, userReducer } from '@autone/ui';
import { configureStore, type Dispatch } from '@reduxjs/toolkit';

import priceTypeReducer from './reducers/priceType';
import { buyingApi, coreApi } from './services';

export const store = configureStore({
  reducer: {
    [buyingApi.reducerPath]: buyingApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    user: userReducer,
    [coreApi.reducerPath]: coreApi.reducer,
    priceType: priceTypeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(coreApi.middleware)
      .concat(buyingApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// TODO: this is a workaround to fix a type error following versions change
export type AppDispatch = Dispatch<any>;
