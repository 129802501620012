import { BUYING_2_PATH_APP } from '@autone/ui';
import { Stack } from '@mui/material';
import { type FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetBatchInfo } from '../../../hooks';
import { getBatchIdFromUrl } from '../../../utils';
import { replaceBatchIdInUrl } from '../../../utils/helpers/getBatchIdFromUrl';

import CurrencyDropdown from './CurrencyDropdown';
import PriceTypeDropdown from './PriceTypeDropdown';
import ShareURLButton from './ShareURLButton';
import StepHeaderTitle from './StepHeaderTitle';

const StepHeader: FC = () => {
  const { batchId } = getBatchIdFromUrl();
  const { pathname } = useLocation();
  const { batchInfo, isLoading, isFetching } = useGetBatchInfo({ batchId });
  const { otb } = batchInfo || {};

  const showPriceTypeDropdown = [
    replaceBatchIdInUrl(BUYING_2_PATH_APP['ideal-buy'], batchId),
    replaceBatchIdInUrl(BUYING_2_PATH_APP['summary'], batchId),
  ].includes(pathname);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <StepHeaderTitle
        batchInfo={batchInfo}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      <Stack direction="row" alignItems="center" spacing={3}>
        {showPriceTypeDropdown && <PriceTypeDropdown otb={otb} />}
        <CurrencyDropdown />
        <ShareURLButton />
      </Stack>
    </Stack>
  );
};

export default StepHeader;
