import { type GetCatalogueSkuCapacitiesResponse } from '@autone/openapi-buying';
import { encodeUrl, type ExtendedCustomBaseQueryType } from '@autone/ui';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { SKU_CAP_ERROR_CODE } from '../../../constants';
import { productsApi } from '../../services/products/products';

export const handleCatalogueDepartmentSkuCapacitiesError = (
  error: FetchBaseQueryError,
) => {
  const detail = (error as any)?.data?.detail;
  if (detail === SKU_CAP_ERROR_CODE) {
    return { error };
  }
};

export const updateCatalogueDepartmentSkuCapacities = async ({
  customBaseQuery,
  dispatch,
  batchId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<GetCatalogueSkuCapacitiesResponse>;
  dispatch: any;
  batchId: string;
}) => {
  const response = await customBaseQuery({
    url: encodeUrl({
      url: `batch/{batchId}/catalogue/sku-capacity`,
      variables: { batchId },
    }),
    method: 'GET',
    extraArgs: { hideSnackbar: true },
  });

  if (response.error) {
    return handleCatalogueDepartmentSkuCapacitiesError(response.error);
  }

  dispatch(
    productsApi.util.updateQueryData(
      'getCatalogueDepartmentSkuCapacities',
      {
        batchId,
      },
      () => {
        return response.data.department_sku_capacities;
      },
    ),
  );

  return { data: null, error: null } as any;
};
