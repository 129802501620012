import { type OTB } from '@autone/openapi-buying';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  priceType: undefined,
} as {
  priceType: OTB['price_type'] | undefined;
};

const priceTypeSlice = createSlice({
  name: 'price-type',
  initialState,
  reducers: {
    SET_PRICE_TYPE: (state, action) => ({
      ...state,
      priceType: action.payload,
    }),
  },
});

export const { SET_PRICE_TYPE } = priceTypeSlice.actions;

export default priceTypeSlice.reducer;
