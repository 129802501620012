import {
  type AddProductLocationQuantificationResponse,
  type Batch,
  type BatchEditQuantificationRequest,
  type BatchEditQuantificationResponse,
  type CreateQuantificationJobRequest,
  type CreateQuantificationJobResponse,
  type EditQuantificationResponse,
  type ExportQuantificationResponse,
  type GetAggregatedQuantificationRequest,
  type GetAggregatedQuantificationResponse,
  type GetQuantificationJobResponse,
  type GetQuantificationKPIsV2Response,
  type OTB,
  type Page_ProductQuantification_,
  type ProductQuantification,
} from '@autone/openapi-buying';
import { encodeUrl, type ExtendedCustomBaseQueryType } from '@autone/ui';

import { WS } from '../../../constants';
import { type BuyingFilters } from '../../../types/filters';
import {
  type EnrichedPageProductQuantification,
  type EnrichedProductQuantification,
  type EnrichedQuantification,
} from '../../../types/ideal-buy';
import { type SortOption } from '../../../types/sort';
import { type BuyingTranslationFunction } from '../../../types/translations';
import { checkHasActiveQuantificationFilters } from '../../../utils/filter';
import { buyingApi } from '../apis';

const getQuantificationOrderValue = (
  product: EnrichedProductQuantification | ProductQuantification,
  orderQuantity: EnrichedQuantification['order_quantity'],
  priceType: OTB['price_type'],
) => {
  if (priceType === WS) {
    return product.price.ws ? product.price.ws * orderQuantity : undefined;
  }

  return product.rrp_excluding_vat
    ? product.rrp_excluding_vat * orderQuantity
    : undefined;
};

export const idealBuyApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    getIdealBuyKpisWithFiltered: builder.query<
      GetQuantificationKPIsV2Response & {
        areFiltersActive: boolean;
      },
      {
        batchId: string;
        currencyIso: string;
        priceType: OTB['price_type'];
        filters: BuyingFilters;
      }
    >({
      query: ({ batchId, currencyIso, priceType, filters }) => ({
        url: encodeUrl({
          url: `v2/batch/${batchId}/quantification/kpis`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          currency_iso: currencyIso,
          price_type: priceType,
          filters,
        },
      }),
      providesTags: ['IdealBuyKpisWithFiltered'],
      transformResponse: (
        response: GetQuantificationKPIsV2Response & {
          areFiltersActive: boolean;
        },
        _meta,
        args,
      ) => {
        const { filters } = args;
        return {
          ...response,
          areFiltersActive: checkHasActiveQuantificationFilters({
            filters,
          }),
        };
      },
    }),
    exportIdealBuy: builder.query<
      ExportQuantificationResponse,
      {
        batchId: string;
        currencyIso: string;
      }
    >({
      query: ({ batchId, currencyIso }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification/export`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          currency_iso: currencyIso,
        },
      }),
    }),
    createIdealBuyJob: builder.query<
      CreateQuantificationJobResponse,
      {
        batchId: string;
        quantificationStrategy: CreateQuantificationJobRequest['strategy'];
        hideSnackbar?: boolean;
      }
    >({
      query: ({ batchId, quantificationStrategy, hideSnackbar = false }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification/job`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          strategy: quantificationStrategy,
        },
        invalidatesTags: ['Batches'],
        extraArgs: { hideSnackbar },
      }),
    }),
    getStatusofIdealBuyJob: builder.query<
      GetQuantificationJobResponse,
      {
        batchId: string;
        jobId: string;
      }
    >({
      query: ({ batchId, jobId }) =>
        encodeUrl({
          url: `batch/{batchId}/quantification/job/{jobId}`,
          variables: { batchId, jobId },
        }),
    }),
    getQuantificationFilters: builder.query<
      BuyingFilters,
      {
        batchId: string;
        buyingCurrencyIso: string;
      }
    >({
      query: ({ batchId, buyingCurrencyIso }) =>
        encodeUrl({
          url: `batch/{batchId}/quantification/filters?currency_iso={buyingCurrencyIso}`,
          variables: { batchId, buyingCurrencyIso },
        }),
      providesTags: ['IdealBuyFilters'],
      transformResponse: (response: { filters: BuyingFilters }) =>
        response.filters,
    }),
    getQuantificationData: builder.query<
      EnrichedPageProductQuantification,
      {
        batchId: Batch['id'];
        activeFilters: BuyingFilters;
        currencyIso: string;
        cursor: string | null;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
      }
    >({
      query: ({
        batchId,
        activeFilters,
        currencyIso,
        cursor,
        limit,
        productSearchTerm,
        sortBy,
      }: {
        batchId: Batch['id'];
        activeFilters: BuyingFilters;
        currencyIso: string;
        cursor: string | null;
        limit: number;
        productSearchTerm?: string;
        sortBy: SortOption['dimension'];
        priceType: OTB['price_type'];
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            cursor: cursor ?? '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: {
          filters: activeFilters,
          currency_iso: currencyIso,
          product_search_term: productSearchTerm,
        },
      }),
      providesTags: ['IdealBuy'],
      transformResponse: (
        response: EnrichedPageProductQuantification,
        _meta,
        args,
      ) => {
        response.items.forEach((product) => {
          const { priceType } = args;
          product.quantification.forEach((quantification) => {
            // we add price to the quantification array in order to calculate the total price
            // we also add order_quantity_sort so the table doesn't jump when being sorted
            quantification.order_value = getQuantificationOrderValue(
              product,
              quantification.order_quantity,
              priceType,
            );

            quantification.order_value_sort = quantification.order_value;
            quantification.order_quantity_sort = quantification.order_quantity;
          });
        });

        return response;
      },
    }),
    patchQuantificationData: builder.mutation<
      EditQuantificationResponse,
      {
        batchId: Batch['id'];
        quantificationId: EnrichedQuantification['id'];
        orderQuantity: number;
        activeFilters: BuyingFilters;
        currencyIso: string;
        productId: string;
        cursor: string | null;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
      }
    >({
      query: ({
        batchId,
        quantificationId,
        orderQuantity,
      }: {
        batchId: Batch['id'];
        quantificationId: EnrichedQuantification['id'];
        orderQuantity: number;
        activeFilters: BuyingFilters;
        currencyIso: string;
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification/{quantificationId}`,
          variables: { batchId, quantificationId },
        }),
        method: 'PATCH',
        body: {
          order_quantity: orderQuantity,
        },
      }),
      async onQueryStarted(
        {
          batchId,
          quantificationId,
          orderQuantity,
          activeFilters,
          currencyIso,
          productId,
          cursor,
          limit,
          productSearchTerm,
          sortBy,
          priceType,
        },
        { dispatch, queryFulfilled },
      ) {
        const patchData = dispatch(
          idealBuyApis.util.updateQueryData(
            'getQuantificationData',
            {
              batchId,
              activeFilters,
              currencyIso,
              cursor,
              limit,
              productSearchTerm,
              sortBy,
              priceType,
            },
            (draft) => {
              const foundProduct = draft.items.find((item) => {
                return item.product.id === productId;
              });

              if (!foundProduct) return;

              const foundSkuLocation = foundProduct.quantification.find(
                (item) => {
                  return item.id === quantificationId;
                },
              );

              if (!foundSkuLocation) return;

              foundSkuLocation.order_quantity = orderQuantity;
              foundSkuLocation.order_value = getQuantificationOrderValue(
                foundProduct,
                orderQuantity,
                priceType,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchData.undo();
        }
      },
      invalidatesTags: [
        'IdealBuyKpisWithFiltered',
        'IdealBuyAggregated',
        'Batches',
      ],
    }),
    patchBulkQuantificationData: builder.mutation<
      BatchEditQuantificationResponse,
      {
        batchId: Batch['id'];
        data: BatchEditQuantificationRequest['quantifications'];
        activeFilters: BuyingFilters;
        currencyIso: string;
        productId: string;
        cursor: string | null;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
      }
    >({
      query: ({
        batchId,
        data,
      }: {
        batchId: Batch['id'];
        data: BatchEditQuantificationRequest['quantifications'];
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification`,
          variables: { batchId },
        }),
        method: 'PATCH',
        body: {
          quantifications: data,
        },
      }),
      async onQueryStarted(
        {
          batchId,
          activeFilters,
          currencyIso,
          productId,
          cursor,
          limit,
          productSearchTerm,
          sortBy,
          data,
          priceType,
        },
        { dispatch, queryFulfilled },
      ) {
        const patchData = dispatch(
          idealBuyApis.util.updateQueryData(
            'getQuantificationData',
            {
              batchId,
              activeFilters,
              currencyIso,
              cursor,
              limit,
              productSearchTerm,
              sortBy,
              priceType,
            },
            (draft) => {
              const foundProduct = draft.items.find((item) => {
                return item.product.id === productId;
              });

              if (!foundProduct) return;

              data.forEach((item) => {
                const foundSkuLocation = foundProduct.quantification.find(
                  (sku) => {
                    return sku.id === item.quantification_id;
                  },
                );

                if (!foundSkuLocation) return;

                foundSkuLocation.order_quantity = item.order_quantity;
                foundSkuLocation.order_value = getQuantificationOrderValue(
                  foundProduct,
                  item.order_quantity,
                  priceType,
                );
              });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchData.undo();
        }
      },
      invalidatesTags: [
        'IdealBuyKpisWithFiltered',
        'IdealBuyAggregated',
        'Batches',
      ],
    }),
    patchProductLocationQuantificationData: builder.mutation<
      EditQuantificationResponse,
      {
        batchId: Batch['id'];
        productId: string;
        locationId: string;
        orderQuantity: number;
        activeFilters: BuyingFilters;
        currencyIso: string;
        cursor: string | null;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
      }
    >({
      invalidatesTags: [
        'IdealBuyKpisWithFiltered',
        'IdealBuyAggregated',
        'Batches',
      ],
      async queryFn(
        {
          batchId,
          productId,
          locationId,
          orderQuantity,
          activeFilters,
          currencyIso,
          cursor,
          limit,
          productSearchTerm,
          sortBy,
          priceType,
        },
        { dispatch },
        _extraOption,
        customBaseQuery: ExtendedCustomBaseQueryType<EditQuantificationResponse>,
      ) {
        const { error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/quantification/product/{productId}/location/{locationId}`,
            variables: { batchId, productId, locationId },
          }),
          method: 'PATCH',
          body: {
            order_quantity: orderQuantity,
          },
          extraArgs: { hideSnackbar: true },
        });

        if (error) {
          return { error };
        }

        const { data, error: refetchQuantificationError } =
          (await customBaseQuery({
            url: encodeUrl({
              url: `batch/{batchId}/quantification?limit=5`,
              variables: { batchId },
            }),
            method: 'POST',
            body: {
              filters: activeFilters,
              currency_iso: currencyIso,
              product_search_term: `${productId}`,
            },
          })) as { data: Page_ProductQuantification_; error: any };

        if (refetchQuantificationError) {
          return { error: refetchQuantificationError };
        }

        const returnedProductData = data.items.find(
          (item) => item.product.id === productId,
        );

        if (!returnedProductData) return { data: null } as any;

        dispatch(
          idealBuyApis.util.updateQueryData(
            'getQuantificationData',
            {
              batchId,
              activeFilters,
              currencyIso,
              cursor,
              limit,
              productSearchTerm,
              sortBy,
              priceType,
            },
            // eslint-disable-next-line consistent-return
            (draft) => {
              // find the product in the cached data
              const foundProduct = draft.items.find((item) => {
                return item.product.id === productId;
              });

              if (!foundProduct) return { data: null } as any;

              // find the locations in the cached data
              const filteredSkuLocations = foundProduct.quantification.filter(
                (item) => {
                  return item.location.id === locationId;
                },
              );

              // iterate through all skus for the changed location and update the order quantity
              filteredSkuLocations.forEach((item) => {
                returnedProductData.quantification.forEach(
                  (returnedDataItem) => {
                    if (
                      item.sku.sku_id === returnedDataItem.sku.sku_id &&
                      item.location.id === returnedDataItem.location.id
                    ) {
                      item.order_quantity = returnedDataItem.order_quantity;

                      item.order_value = getQuantificationOrderValue(
                        returnedProductData,
                        returnedDataItem.order_quantity,
                        priceType,
                      );
                    }
                  },
                );
              });
            },
          ),
        );
        return { data: null } as any;
      },
    }),
    getIdealBuyHighLevelTable: builder.query<
      GetAggregatedQuantificationResponse['quantification'],
      {
        batchId: Batch['id'];
        activeFilters: GetAggregatedQuantificationRequest['filters'];
        currencyIso: GetAggregatedQuantificationRequest['currency_iso'];
        metrics: GetAggregatedQuantificationRequest['metrics'];
        aggregations: GetAggregatedQuantificationRequest['aggregations'];
        priceType: OTB['price_type'];
      }
    >({
      query: ({
        batchId,
        activeFilters,
        currencyIso,
        metrics,
        aggregations,
        priceType,
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/quantification/aggregated`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          filters: activeFilters,
          currency_iso: currencyIso,
          metrics,
          aggregations,
          price_type: priceType,
        },
      }),
      transformResponse: (response: {
        quantification: GetAggregatedQuantificationResponse['quantification'];
      }) => response.quantification,
      providesTags: ['IdealBuyAggregated'],
    }),
    patchProductLocationQuantification: builder.mutation<
      AddProductLocationQuantificationResponse,
      {
        batchId: string;
        productId: string;
        locationId: string;
        activeFilters: BuyingFilters;
        currencyIso: string;
        cursor: string | null;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
        t: BuyingTranslationFunction;
      }
    >({
      async queryFn(
        {
          batchId,
          productId,
          locationId,
          activeFilters,
          currencyIso,
          cursor,
          limit,
          productSearchTerm,
          sortBy,
          priceType,
        },
        { dispatch },
        _extraOption,
        customBaseQuery: ExtendedCustomBaseQueryType<AddProductLocationQuantificationResponse>,
      ) {
        const { error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/quantification/product-location`,
            variables: { batchId },
          }),
          method: 'POST',
          extraArgs: { hideSnackbar: true },
          body: {
            product_id: productId,
            location_id: locationId,
          },
        });

        if (error) {
          return { error };
        }

        const { data: refetchData, error: refetchQuantificationError } =
          (await customBaseQuery({
            url: encodeUrl({
              url: `batch/{batchId}/quantification?limit=1`,
              variables: { batchId },
            }),
            method: 'POST',
            body: {
              filters: activeFilters,
              currency_iso: currencyIso,
              product_search_term: `${productId}`,
            },
          })) as { data: Page_ProductQuantification_; error: any };

        if (refetchQuantificationError) {
          return { error: refetchQuantificationError };
        }

        const returnedProductData = refetchData?.items.find(
          (item) => item.product.id === productId,
        );
        const foundProductLocationSkus = returnedProductData?.quantification
          .filter((item) => item.location.id === locationId)
          .map((item) => ({
            ...item,
            order_value: 0,
            order_value_sort: 0,
            order_quantity_sort: 0,
            newly_added: new Date().toISOString(),
          }));
        if (!foundProductLocationSkus) return { data: null } as any;

        dispatch(
          idealBuyApis.util.updateQueryData(
            'getQuantificationData',
            {
              batchId,
              activeFilters,
              currencyIso,
              cursor,
              limit,
              productSearchTerm,
              sortBy,
              priceType,
            },
            (draft) => {
              const foundProduct = draft.items.find(
                ({ product: { id } }) => id === productId,
              );
              if (!foundProduct) return;

              foundProduct.quantification = foundProduct.quantification.concat(
                foundProductLocationSkus,
              );
            },
          ),
        );
        return { data: null } as any;
      },
      invalidatesTags: ['IdealBuyFilters'],
    }),
    patchProductQuantity: builder.mutation<
      EditQuantificationResponse,
      {
        batchId: Batch['id'];
        productId: string;
        orderQuantity: number;
        activeFilters: BuyingFilters;
        currencyIso: string;
        limit?: number;
        productSearchTerm?: string;
        sortBy?: SortOption['dimension'];
        priceType: OTB['price_type'];
      }
    >({
      invalidatesTags: [
        'IdealBuyKpisWithFiltered',
        'IdealBuyAggregated',
        'Batches',
      ],
      async queryFn(
        {
          batchId,
          productId,
          orderQuantity,
          activeFilters,
          currencyIso,
          limit,
          productSearchTerm,
          sortBy,
          priceType,
        },
        { dispatch },
        _extraOption,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditQuantificationResponse>
          | ExtendedCustomBaseQueryType<Page_ProductQuantification_>,
      ) {
        const { error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/quantification/product/{productId}`,
            variables: { batchId, productId },
          }),
          method: 'PATCH',
          body: {
            order_quantity: orderQuantity,
          },
        });

        if (error) {
          return { error };
        }
        const { data: refetchData, error: refetchQuantificationError } =
          (await customBaseQuery({
            url: encodeUrl({
              url: `batch/{batchId}/quantification?limit=5`,
              variables: { batchId },
            }),
            method: 'POST',
            body: {
              filters: { geographic: {}, product: {} },
              currency_iso: currencyIso,
              product_search_term: `${productId}`,
            },
          })) as { data: Page_ProductQuantification_; error: any };

        if (refetchQuantificationError) {
          return { error: refetchQuantificationError };
        }

        const returnedProductData = refetchData.items.find(
          (item) => item.product.id === productId,
        );

        if (!returnedProductData) return { data: null } as any;

        dispatch(
          idealBuyApis.util.updateQueryData(
            'getQuantificationData',
            {
              batchId,
              activeFilters,
              currencyIso,
              limit,
              cursor: null,
              productSearchTerm,
              sortBy,
              priceType,
            },
            // eslint-disable-next-line consistent-return
            (draft) => {
              const foundProduct = draft.items.find((item) => {
                return item.product.id === productId;
              });
              if (!foundProduct) return;

              foundProduct.quantification.forEach((quantification) => {
                const foundSkuLocation =
                  returnedProductData.quantification.find((item) => {
                    return item.id === quantification.id;
                  });

                if (!foundSkuLocation) return;

                quantification.order_quantity = foundSkuLocation.order_quantity;
                quantification.order_value = getQuantificationOrderValue(
                  foundProduct,
                  foundSkuLocation.order_quantity,
                  priceType,
                );
              });
            },
          ),
        );
        return { data: null } as any;
      },
    }),
  }),
});

export const {
  useLazyGetQuantificationDataQuery,
  useGetQuantificationDataQuery,
  usePatchQuantificationDataMutation,
  useGetQuantificationFiltersQuery,
  useLazyExportIdealBuyQuery,
  useLazyCreateIdealBuyJobQuery,
  useGetIdealBuyHighLevelTableQuery,
  useGetStatusofIdealBuyJobQuery,
  useGetIdealBuyKpisWithFilteredQuery,
  useLazyGetIdealBuyKpisWithFilteredQuery,
  usePatchBulkQuantificationDataMutation,
  usePatchProductLocationQuantificationDataMutation,
  usePatchProductQuantityMutation,
  usePatchProductLocationQuantificationMutation,
} = idealBuyApis;
