import {
  type CommonTranslationFunction,
  i18nAutone,
} from '@autone/translations';

import { type BuyingTranslationFunction } from '../../../types/translations';

export type ErrorTypes = {
  NOT_IN_SCOPE_ERROR: string;
  IMPORT_JOB_NOT_FOUND: string;
  EXPORT_JOB_NOT_FOUND: string;
  NO_SIZE_CURVE: string;
  CANNOT_EDIT_ASSORTMENT: string;
  CANNOT_EDIT_PRODUCT_IDS: string;
  NO_SKU_CAPACITIES: string;
  QUANTIFICATION_DATA_NOT_FOUND: string;
  BATCH_NOT_FOUND: string;
  INVALID_BATCH_STATUS: string;
  INVALID_BATCH_STEP_CHANGE: string;
  INVALID_SELL_PERIODS: string;
  PRODUCT_NOT_FOUND: string;
  CUSTOMER_NOT_FOUND: string;
  INSIGHTS_JOB_NOT_FOUND: string;
  OTB_NOT_FOUND: string;
  EMPTY_OTB: string;
  INVALID_OTB_TEMPLATE: string;
  PRODUCT_POTENTIAL_JOB_NOT_FOUND: string;
  INVALID_FILE_FORMAT: string;
  INVALID_EXCEL_SHEET_NAME: string;
  INVALID_PAGINATION_CURSOR: string;
  LOCATION_NOT_FOUND: string;
  ORDER_QUANTITIES_NOT_FOUND: string;
  QUANTIFICATION_JOB_NOT_FOUND: string;
  VALIDATION_ERROR: string;
};

export const getErrors = (): ErrorTypes => {
  const getBuyingErrorMessage = (
    translatingString: Parameters<BuyingTranslationFunction>[0],
  ) => i18nAutone.t(`buying:${translatingString}`);

  const getCommonErrorMessage = (
    translatingString: Parameters<CommonTranslationFunction>[0],
  ) => i18nAutone.t(`common:${translatingString}`);

  return {
    NOT_IN_SCOPE_ERROR: getBuyingErrorMessage('errors.messages.not-in-scope'),
    IMPORT_JOB_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.import-job-not-found',
    ),
    EXPORT_JOB_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.export-job-not-found',
    ),
    NO_SIZE_CURVE: getBuyingErrorMessage('errors.messages.no-size-curve'),
    CANNOT_EDIT_ASSORTMENT: getBuyingErrorMessage(
      'errors.messages.cannot-edit-assortment',
    ),
    CANNOT_EDIT_PRODUCT_IDS: getBuyingErrorMessage(
      'errors.messages.cannot-edit-product-ids',
    ),
    NO_SKU_CAPACITIES: getBuyingErrorMessage(
      'errors.messages.no-sku-capacities',
    ),
    QUANTIFICATION_DATA_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.quantification-data-not-found',
    ),
    BATCH_NOT_FOUND: getBuyingErrorMessage('errors.messages.batch-not-found'),
    INVALID_BATCH_STATUS: getBuyingErrorMessage(
      'errors.messages.invalid-batch-status',
    ),
    INVALID_BATCH_STEP_CHANGE: getBuyingErrorMessage(
      'errors.messages.invalid-batch-step-change',
    ),
    INVALID_SELL_PERIODS: getBuyingErrorMessage(
      'errors.messages.invalid-sell-periods',
    ),
    PRODUCT_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.product-not-found',
    ),
    CUSTOMER_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.customer-not-found',
    ),
    INSIGHTS_JOB_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.insights-job-not-found',
    ),
    OTB_NOT_FOUND: getBuyingErrorMessage('errors.messages.otb-not-found'),
    EMPTY_OTB: getBuyingErrorMessage('errors.messages.empty-otb'),
    INVALID_OTB_TEMPLATE: getBuyingErrorMessage(
      'errors.messages.invalid-otb-template',
    ),
    PRODUCT_POTENTIAL_JOB_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.product-potential-job-not-found',
    ),
    INVALID_FILE_FORMAT: getBuyingErrorMessage(
      'errors.messages.invalid-file-format',
    ),
    INVALID_EXCEL_SHEET_NAME: getBuyingErrorMessage(
      'errors.messages.invalid-excel-sheet-name',
    ),
    INVALID_PAGINATION_CURSOR: getBuyingErrorMessage(
      'errors.messages.invalid-pagination-cursor',
    ),
    LOCATION_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.location-not-found',
    ),
    ORDER_QUANTITIES_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.order-quantities-not-found',
    ),
    QUANTIFICATION_JOB_NOT_FOUND: getBuyingErrorMessage(
      'errors.messages.quantification-job-not-found',
    ),
    VALIDATION_ERROR: getCommonErrorMessage('errors.messages.validation-error'),
  };
};
