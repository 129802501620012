import {
  type Assortment,
  type AssortmentEditable,
  type AssortmentGrouping,
  type Batch,
  type GetAssortmentMismatchesResponse,
} from '@autone/openapi-buying';
import { encodeUrl, type ExtendedCustomBaseQueryType } from '@autone/ui';

import { SELECTED_OPTIONS } from '../../../features/assortment/constants';
import {
  type EnrichedAssortmentAggregationResponse,
  type EnrichedAssortmentData,
} from '../../../types/assortment';
import { type BuyingFilters } from '../../../types/filters';
import { updateAssortmentTableCache } from '../../helpers/assortment';
import { buyingApi } from '../apis';

const getAssortmentTotals = (data: EnrichedAssortmentAggregationResponse) => {
  data.assortments.forEach((item) => {
    item.assortment.forEach((assortmentItem) => {
      assortmentItem.assortment_count_sort = assortmentItem.assortment_count;
    });
  });

  return data;
};

export const assortmentApi = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssortmentData: builder.query<
      EnrichedAssortmentData,
      {
        batchId: Batch['id'];
        activeFilters: BuyingFilters;
        aggregation: EnrichedAssortmentData['aggregation'];
        currencyIso: string;
      }
    >({
      query: ({
        batchId,
        activeFilters,
        aggregation,
        currencyIso,
      }: {
        batchId: Batch['id'];
        activeFilters: BuyingFilters;
        aggregation: EnrichedAssortmentData['aggregation'];
        currencyIso: string;
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/assortment`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          filters: activeFilters,
          aggregation,
          currency_iso: currencyIso,
        },
      }),
      transformResponse: (response: { assortment: EnrichedAssortmentData }) => {
        // we transform the response to add totals to the product options and sort fields
        // we add the latter so the table doesn't jump when the user changes the selected state
        response.assortment.product_options.forEach((productOption) => {
          productOption.total_selected =
            productOption.aggregation_options.filter(
              ({ selected }) =>
                selected === SELECTED_OPTIONS.SELECTED ||
                selected === SELECTED_OPTIONS.PARTIALLY_SELECTED,
            ).length;

          productOption.total_selected_sort = productOption.total_selected;

          productOption.aggregation_options.forEach((aggregationOption) => {
            aggregationOption.selected_sort = aggregationOption.selected;
          });
        });

        return response.assortment;
      },
      providesTags: ['Assortment'],
    }),
    updateAssortmentData: builder.mutation<
      void,
      {
        batchId: string;
        activeFilters: BuyingFilters;
        currencyIso: string;
        payload: AssortmentEditable;
        activeFiltersWithDepartments?: BuyingFilters;
      }
    >({
      invalidatesTags: ['AssortmentMismatches', 'Review'],
      async queryFn(
        {
          batchId,
          activeFilters,
          currencyIso,
          payload,
          activeFiltersWithDepartments,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery,
      ) {
        updateAssortmentTableCache({
          dispatch,
          batchId,
          activeFilters,
          currencyIso,
          payload,
        });

        // if department filters have been passed, then we also need to update that separate cache
        if (activeFiltersWithDepartments) {
          updateAssortmentTableCache({
            dispatch,
            batchId,
            activeFilters: activeFiltersWithDepartments,
            currencyIso,
            payload,
          });
        }

        const { error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/assortment`,
            variables: { batchId },
          }),
          method: 'PATCH',
          body: {
            assortment: payload,
          },
        });

        if (error) {
          // @ts-expect-error
          patchAssortmentData.undo();
          return { error };
        }

        return { data: null } as any;
      },
    }),
    updateAggregationData: builder.mutation<
      void,
      {
        batchId: string;
        activeFilters: BuyingFilters;
        currencyIso: string;
        productDimension: string;
        departmentValue: string;
        locationAggregation: Assortment['aggregation'];
      }
    >({
      invalidatesTags: ['AssortmentMismatches', 'Review'],
      async queryFn(
        {
          batchId,
          activeFilters,
          currencyIso,
          departmentValue,
          productDimension,
          locationAggregation,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery,
      ) {
        const { data, error: aggregationQueryError } = await (
          customBaseQuery as ExtendedCustomBaseQueryType<EnrichedAssortmentAggregationResponse>
        )({
          url: encodeUrl({
            url: `batch/{batchId}/assortment/aggregation`,
            variables: { batchId },
          }),
          method: 'POST',
          body: {
            grouping: { field: 'department_id', value: departmentValue },
            product_aggregation: productDimension,
            location_aggregation: locationAggregation,
            filters: activeFilters,
            currency_iso: currencyIso,
          },
        });

        if (aggregationQueryError) {
          return { error: aggregationQueryError };
        }

        dispatch(
          assortmentApi.util.updateQueryData(
            'getAssortmentAggregation',
            {
              batchId,
              grouping: { field: 'department_id', value: departmentValue },
              product_aggregation: productDimension,
              location_aggregation: locationAggregation,
              filters: activeFilters,
              currency_iso: currencyIso,
            },
            (draft) => {
              draft.defined_sku_caps = data?.defined_sku_caps;

              draft.assortments.forEach((item) => {
                const foundAssortment = data?.assortments.find(
                  (dataItem) =>
                    `${dataItem.product_aggregation.id}-${dataItem.product_aggregation.description}` ===
                    `${item.product_aggregation.id}-${item.product_aggregation.description}`,
                );

                item.assortment.forEach((assortmentItem) => {
                  const foundAssortmentItem = foundAssortment?.assortment.find(
                    (dataItem) =>
                      `${dataItem.location_aggregation.id}-${dataItem.location_aggregation.description}` ===
                      `${assortmentItem.location_aggregation.id}-${assortmentItem.location_aggregation.description}`,
                  );

                  if (foundAssortmentItem?.assortment_count === undefined)
                    return;
                  assortmentItem.assortment_count =
                    foundAssortmentItem?.assortment_count;
                });
              });
            },
          ),
        );

        return { data: null } as any;
      },
    }),
    getAssortmentFilters: builder.query<
      BuyingFilters,
      {
        batchId: Batch['id'];
        buyingCurrencyIso: string;
      }
    >({
      query: ({ batchId, buyingCurrencyIso }) =>
        encodeUrl({
          url: `batch/{batchId}/assortment/filters?currency_iso={buyingCurrencyIso}`,
          variables: { batchId, buyingCurrencyIso },
        }),
      transformResponse: (response: { filters: BuyingFilters }) =>
        response.filters,
      providesTags: ['AssortmentFilters'],
    }),
    getAssortmentMismatches: builder.query<
      GetAssortmentMismatchesResponse,
      {
        batchId: Batch['id'];
      }
    >({
      query: ({ batchId }) =>
        encodeUrl({
          url: `batch/{batchId}/assortment/mismatches`,
          variables: { batchId },
        }),
      providesTags: ['AssortmentMismatches'],
    }),
    getAssortmentAggregation: builder.query<
      EnrichedAssortmentAggregationResponse,
      {
        batchId: string;
        grouping: AssortmentGrouping;
        product_aggregation: string;
        location_aggregation: Assortment['aggregation'];
        filters: BuyingFilters;
        currency_iso: string;
      }
    >({
      query: ({
        batchId,
        grouping,
        product_aggregation,
        location_aggregation,
        filters,
        currency_iso,
      }: {
        batchId: string;
        grouping: { field: string; value: string };
        product_aggregation: string;
        location_aggregation: Assortment['aggregation'];
        filters: BuyingFilters;
        currency_iso: string;
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/assortment/aggregation`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          grouping,
          product_aggregation,
          location_aggregation,
          filters,
          currency_iso,
        },
      }),
      transformResponse: (response: EnrichedAssortmentAggregationResponse) => {
        return getAssortmentTotals(response);
      },
    }),
  }),
});

export const {
  useGetAssortmentFiltersQuery,
  useGetAssortmentDataQuery,
  useUpdateAssortmentDataMutation,
  useGetAssortmentMismatchesQuery,
  useGetAssortmentAggregationQuery,
  useUpdateAggregationDataMutation,
} = assortmentApi;
