import { useAutoneTranslation } from '@autone/translations';
import { type MetricId, useAvailablePriceTypes } from '@autone/ui';
import { sortBy } from 'lodash';

import { type APP_NAME } from '../../constants';
import { useAppSelector as useSelector } from '../../redux/hooks';

function isNotIc(name: MetricId): name is Exclude<MetricId, 'ic'> {
  return name !== 'ic';
}

const useGetPriceTypes = () => {
  const { priceType } = useSelector((state) => state.priceType);
  const { availablePriceTypes } = useAvailablePriceTypes();
  const { t } = useAutoneTranslation<typeof APP_NAME>();

  const priceTypeOptions = sortBy(
    availablePriceTypes
      ?.filter(({ name }) => isNotIc(name))
      ?.map(({ name }) => ({
        value: name as Exclude<MetricId, 'ic'>,
        label: t(
          `buy-form.open-to-buy.pricing.${name as Exclude<MetricId, 'ic'>}`,
        ),
      })),
    ['label'],
  );

  return {
    priceTypeOptions,
    priceType,
  };
};

export default useGetPriceTypes;
