import {
  type BatchScope,
  type EditBatchScopeResponse,
  type GeographicScope,
  type GetBatchScopeResponse,
  type ProductScope,
  type ScopeOption,
} from '@autone/openapi-buying';
import { encodeUrl, isKeyInType, isOfType } from '@autone/ui';

import { type QueryReturnValue } from '../../../types/rtk-query';
import { buyingApi } from '../apis';

const findScopeItem = (
  item: ScopeOption,
  filterPayload: Record<string, ScopeOption[]>,
  scopeOption: string,
) => {
  const foundScopeItem = filterPayload[scopeOption].find(
    (scopeItem: ScopeOption) =>
      scopeItem.id === item.id && scopeItem.description === item.description,
  );

  if (!foundScopeItem) return;

  item.selected = foundScopeItem.selected;
};

const scopeApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    getScope: builder.query<GetBatchScopeResponse, { batchId: string }>({
      query: ({ batchId }: { batchId: string }) =>
        encodeUrl({ url: `batch/{batchId}/scope`, variables: { batchId } }),
      providesTags: ['Scope'],
    }),
    postScope: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        filterPayload: Record<string, ScopeOption[]>;
        scopeOption: string;
        scopeType: string;
      }
    >({
      async queryFn(
        { batchId, scopeOption, scopeType, filterPayload },
        { dispatch },
        _extraOptions,
        customBaseQuery,
      ) {
        // 1. update the cache optimistically, just for the dropdown being selected
        const patchResult = dispatch(
          scopeApis.util.updateQueryData('getScope', { batchId }, (draft) => {
            // if geographic scope, update the selected value for the dropdown
            if (
              isOfType<BatchScope>(draft.scope, 'geographic') &&
              isKeyInType(draft.scope, 'geographic') &&
              isOfType<GeographicScope>(draft.scope.geographic, scopeOption) &&
              isKeyInType(draft.scope.geographic, scopeOption)
            ) {
              draft.scope.geographic[scopeOption].forEach(
                (item: ScopeOption) => {
                  findScopeItem(item, filterPayload, scopeOption);
                },
              );
              // if product scope, update the selected value for the dropdown
            } else if (
              isOfType<BatchScope>(draft.scope, scopeType) &&
              isKeyInType(draft.scope, scopeType) &&
              isOfType<ProductScope>(draft.scope.products, scopeOption) &&
              isKeyInType(draft.scope.products, scopeOption)
            ) {
              draft.scope.products[scopeOption].forEach((item: ScopeOption) => {
                findScopeItem(item, filterPayload, scopeOption);
              });
            }
          }),
        );

        // 2. send the update to the api
        const { data, error } = await customBaseQuery({
          url: encodeUrl({
            url: `batch/{batchId}/scope`,
            variables: { batchId },
          }),
          method: 'POST',
          body: { scope: { [scopeType]: filterPayload } },
        });

        const returnedData = data as EditBatchScopeResponse;

        // 3. if the request fails, undo the cache update and return an error
        if (error) {
          // Luke - can't figure out hjow to type the undo
          // @ts-ignore
          patchResult.undo();
          return { error };
        }

        // EditBatchScopeResponse
        // 3. update the cache for all other scope cards
        dispatch(
          scopeApis.util.updateQueryData('getScope', { batchId }, (draft) => {
            // get the scope keys and remove the one we just updated

            const keys = isKeyInType(returnedData.scope, scopeType)
              ? Object.keys(returnedData.scope[scopeType])
              : [];

            const keysToUpdate = keys.filter(
              (keyInCache) => keyInCache !== scopeOption,
            );

            // loop the scope keys and update the cache with the api response
            keysToUpdate.forEach((keyToUpdate) => {
              if (
                isOfType<BatchScope>(draft.scope, 'geographic') &&
                isKeyInType(draft.scope, 'geographic') &&
                isOfType<GeographicScope>(
                  draft.scope.geographic,
                  keyToUpdate,
                ) &&
                isKeyInType(draft.scope.geographic, keyToUpdate)
              ) {
                draft.scope.geographic[keyToUpdate] =
                  returnedData.scope.geographic[keyToUpdate];
              } else if (
                isOfType<BatchScope>(draft.scope, 'products') &&
                isKeyInType(draft.scope, 'products') &&
                isOfType<ProductScope>(draft.scope.products, keyToUpdate) &&
                isKeyInType(draft.scope.products, keyToUpdate)
              ) {
                draft.scope.products[keyToUpdate] =
                  returnedData.scope.products[keyToUpdate];
              }
            });
          }),
        );

        // this return is not used, but is here to satisfy the queryFn response type
        // the any type is also there to satisfy the queryFn types
        return { data: null } as any;
      },
      invalidatesTags: ['ProductMismatches', 'AssortmentMismatches'],
    }),
  }),
});

export const { useGetScopeQuery, usePostScopeMutation } = scopeApis;
