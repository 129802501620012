import { type QuantificationJob } from '@autone/openapi-buying';

type ValidBuyingErrorCodes = Exclude<
  QuantificationJob['error_code'],
  null | 'INTERNAL_ERROR' | 'UNKNOWN'
>;

type BuyingErrorCodes = {
  [key in ValidBuyingErrorCodes]: {
    header: string;
    description: string;
  };
};

export const ERROR_CODES: BuyingErrorCodes = {
  BATCH_NOT_FOUND: {
    header: 'No batch found',
    description: 'No batch has been found, please contact support',
  },
  NO_SKU_IN_ASSORMTENT: {
    header: 'No SKUs are in assortment',
    description:
      'Products in your scope have no associated SKUs, please update your data or assortment',
  },
  NO_ACTIVE_PRODUCT_IN_ASSORTMENT: {
    header: 'No active products are in assortment',
    description:
      'Products in your scope are non-active, please update your data or assortment',
  },
  EMPTY_SIZE_CURVES: {
    header: 'No size curves calculated',
    description: 'No size curves were forecasted, please contact support',
  },
  OTB_SPLIT_FAILURE: {
    header: 'OTB was unable to be split',
    description:
      'Unable to split your OTB based on dimensions provided, please contact support',
  },
  CATALOG_RETRIVAL_FAILURE: {
    header: 'Unable to retrieve your catalogue',
    description: 'Unable to retrieve your catalogue, please contact support',
  },
  ASSORTMENT_RETRIVAL_FAILURE: {
    header: 'Unable to retrieve your assortment',
    description: 'Unable to retrieve your assortment, please contact support',
  },
  CURRENCY_NOT_FOUND: {
    header: 'No currency found',
    description: 'No currency has been found, please contact support',
  },
  HISTORICAL_PRODUCT_SALES_FAILURE: {
    header: 'No historic sales data found',
    description:
      'No historic sales data has been found, please contact support',
  },
  HISTORICAL_SALES_FAILURE: {
    header: 'No historic sales data found',
    description:
      'No historic sales data has been found, please contact support',
  },
  NO_NEW_ASSORMENT_TO_QUANTIFY: {
    header: 'No new products to quantify',
    description: 'No new products to quantify, please adjust your assortment',
  },
};
