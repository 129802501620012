import {
  type CreateInsightsJobResponse,
  type GetInsightsFiltersResponse,
  type GetInsightsJobResponse,
  type GetProductInsightsResponse,
  type InsightsFilters_Input,
  type InsightsFilters_Output,
  type InsightsProduct,
} from '@autone/openapi-buying';
import { encodeUrl } from '@autone/ui';

import { buyingApi } from '../apis/buying';

const insightsApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    getInsightsFilters: builder.query<
      InsightsFilters_Output['product'],
      { batchId: string; productId: string }
    >({
      query: ({ batchId, productId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/insights/product/{productId}/filters`,
          variables: { batchId, productId },
        }),
        method: 'GET',
      }),
      transformResponse: (response: GetInsightsFiltersResponse) =>
        response.filters.product,
    }),
    getInsights: builder.query<
      InsightsProduct[],
      { batchId: string; productId: string; filters: InsightsFilters_Input }
    >({
      query: ({ batchId, productId, filters }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/insights/product/{productId}`,
          variables: { batchId, productId },
        }),
        method: 'POST',
        body: { filters },
      }),
      transformResponse: (response: GetProductInsightsResponse) =>
        response.products,
    }),
    createInsightsJob: builder.query<
      CreateInsightsJobResponse,
      { batchId: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/insights/job`,
          variables: { batchId },
        }),
        method: 'POST',
      }),
    }),
    getStatusOfInsightsJob: builder.query<
      GetInsightsJobResponse,
      { batchId: string; jobId: string }
    >({
      query: ({ batchId, jobId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/insights/job/{jobId}`,
          variables: { batchId, jobId },
        }),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetInsightsFiltersQuery,
  useGetInsightsQuery,
  useLazyCreateInsightsJobQuery,
  useGetStatusOfInsightsJobQuery,
} = insightsApis;
